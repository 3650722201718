import moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export const formatToHuman = (date) => moment(date).format('DD/MM/YYYY');

export const givenYearsAgo = (years) => moment(new Date()).subtract(years, 'years').format('YYYY-MM-DD');

export const age = (dob) => moment().diff(dob, 'years');

export const formatDate = (date) => {
  if (!date) return null;

  const [year, month, day] = date.split('-');
  return `${day}/${month}/${year}`;
};

export const formatDate2 = (date) => {
  if (!date) return null;

  const [year, month, day] = date.split('-');
  return `${year}-${month}-${day}`;
};

export const parseDate = (date) => {
  if (!date) return null;

  const [month, day, year] = date.split('/');
  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
};

export const changeFormat = (date) => {
  if (date !== null) {
    const d = date.split('-');
    return `${d[2]}/${d[1]}/${d[0]}`;
  }
  return '';
};

export const reverseFormat = (date) => {
  if (date !== null) {
    const d = date.split('/');
    return `${d[2]}-${d[1]}-${d[0]}`;
  }
  return '';
};

export const addMonths = (m) => moment().add(m, 'months').format('Do MMMM YYYY');

export const diffDate = (date) => {
  const now = moment(new Date());
  return moment(date, 'YYYY-MM-DD').isBefore(now);
};

export const diffDate2 = (date) => {
  const now = moment(new Date());
  return moment(date, 'Do MMM YYYY').isBefore(now);
};

export const isInstalmentDue = (paymentSchedule) => {
  let s = false;
  if (paymentSchedule === null) return false;
  paymentSchedule.forEach((i) => {
    if (diffDate2(i.dueDate)) {
      s = true;
    }
  });
  return s;
};

export const timeNow = (i = null) => {
  if (moment(new Date(i)).isAfter()) {
    return '08:00';
  }
  const d = new Date();
  const h = (d.getHours() < 10 ? '0' : '') + d.getHours();
  const m = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
  // eslint-disable-next-line no-param-reassign
  return `${h}:${m}`;
};
